// ** Logo
// import logo from '@src/assets/images/logo/tip-color-logo.svg'
import {Spinner} from 'reactstrap'

const SpinnerComponent = () => {
    return (
        <div className='fallback-spinner'>
            <div className='loading' >
                <Spinner color='primary' style={{width: '60px', height: '60px'}} />
            </div>
        </div>
    )
}

export default SpinnerComponent
