import i18n from "i18next";
import {initReactI18next} from "react-i18next"

import ar from '@lang/ar/index.json'
import en from '@lang/en/index.json'

import {getCurrentLang} from "@hooks/useLng"
import env  from "@src/env.json"
const resources = {
    en: {
        translation: {
            ...en
        }
    },
    ar: {
        translation: {
            ...ar
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getCurrentLang(),
        fallbackLng: 'en',
        debug: env.MODE === 'dev',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n