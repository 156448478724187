let initialState = {
    imageProfile: `https://ui-avatars.com/api/?name=admin&color=7F9CF5&background=EBF4FF`,
    userData: {
        id : '',
        username : '',
        email : '',
        firstname : '',
        lastname : '',
        role_id : ''
    },
}

const authReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN' :
            const payload = action.payload
            initialState = {
                userData: payload.user,
            }
            const imageProfile = `https://ui-avatars.com/api/?name=${payload.user.username || payload.user.firstname}&color=7F9CF5&background=EBF4FF`
            return {imageProfile, ...initialState}
        case 'LOGOUT':
            initialState = {}
            return initialState
        default :
            return initialState
    }
}

export default authReducers