let initialState = {}

const formDataReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FORM_DATA' :
            const payload = action.payload
            initialState = {...payload}
            return initialState
        case 'DELETE_FORM_DATA':
            initialState = {}
            return initialState
        default :
            return initialState
    }
}

export default formDataReducers