// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import formData from './form-data'
import datatable from "./datatable"

const rootReducer = combineReducers({
    auth,
    navbar,
    layout,
    formData,
    datatable
})

export default rootReducer
