// ** React Imports
import { Suspense, lazy, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider, useSelector, useDispatch } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Axios request handler
import axios from "axios"

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import error from "./views/Error";

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
import env from "@src/env.json";

// Add a request interceptor
axios.defaults.baseURL = env.API_BASE_URL

// const token = localStorage.getItem('tk')
// axios.interceptors.request.use(config => {
//     if (config.url !== '/auth' || config.url !== '/register'){
//         config.headers = {
//             Authorization : {Authorization: `Bearer ${token}`}
//         }
//     }
//     return config;
// }, error => {
//     return Promise.reject(error);
// });

// Add a response interceptor
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401){
        localStorage.removeItem('tk');
        localStorage.removeItem('user_data');
        location.href = '/login'
    }

    // if (error.response.status === 500){
    //     location.href = '/server-error'
    // }

    return Promise.reject(error);
});

import { IntlProviderWrapper } from './utility/context/Internationalization'


ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
                <ThemeContext>
                    <IntlProviderWrapper>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </IntlProviderWrapper>
                </ThemeContext>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()
