import {useEffect} from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import errorImg from '@src/assets/images/brand/light/404.svg'

import logo from '@src/assets/images/logo/tip-color-logo.svg'
import langConfig from "@configs/langConfig"
import '@styles/base/pages/page-misc.scss'

const Error = () => {
  const { t } = langConfig
    useEffect(_ => {
        document.title = `${document.title} | 404`
    }, [])
  return (
    <div className='misc-wrapper'>
      <a className='brand-logo' href='/'>
        <img src={logo} alt='logo' width='150'/>
      </a>
      <div className='misc-inner p-2 p-sm-3'>
        <div className='w-100 text-center'>
          <h2 className='mb-1'>{t('Page Not Found 🕵🏻‍♀')}️</h2>
          <p className='mb-2'>{t('Oops! 😖 The requested URL was not found on this server.')}</p>
          <Button.Ripple tag={Link} to='/' color='primary' className='btn-sm-block mb-2'>
              {t('Back to dashboard')}
          </Button.Ripple>
        </div>
          <div>
              <img className='img-fluid' width='600'  src={errorImg} alt='Not authorized page' />
          </div>
      </div>
    </div>
  )
}
export default Error
